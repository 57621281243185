<template>
    <div class="min-h-screen flex flex-col bg-gradient-to-br from-gray-900 to-black text-gray-100">
      <!-- Header -->
      <header class="py-4 sm:py-6 px-4 sm:px-6 absolute w-full z-10">
        <div class="container mx-auto flex flex-col sm:flex-row justify-between items-center">
          <div class="text-2xl font-bold text-white mb-4 sm:mb-0">Juárez IT PRO</div>
          <nav class="w-full sm:w-auto">
            <ul class="flex flex-wrap justify-center sm:justify-end space-x-4 sm:space-x-6">
              <li v-for="item in navItems" :key="item">
                <a 
                  href="#" 
                  class="text-gray-300 hover:text-white transition-colors duration-300 text-sm uppercase tracking-wider"
                >
                  {{ item }}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
  
      <!-- Main Content -->
      <main class="flex-grow flex items-center justify-center px-4 sm:px-6 py-16 sm:py-0">
        <div class="max-w-4xl mx-auto text-center">
          <h1 class="text-4xl sm:text-5xl md:text-7xl font-bold text-white mb-4 sm:mb-6 animate-fade-in-up">
            Juárez IT PRO
          </h1>
          <p class="text-2xl sm:text-3xl md:text-4xl text-gray-300 mb-6 sm:mb-8 animate-fade-in-up animation-delay-300">
            PRÓXIMAMENTE
          </p>
          <p class="text-lg sm:text-xl text-gray-400 mb-8 sm:mb-12 max-w-2xl mx-auto animate-fade-in-up animation-delay-600">
            Estamos preparando algo extraordinario. 
            Mantente al tanto para ser parte de la revolución tecnológica.
          </p>
          <form @submit.prevent="subscribeNewsletter" class="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 animate-fade-in-up animation-delay-900">
            <input 
              type="email" 
              v-model="email" 
              placeholder="Tu correo electrónico" 
              class="px-4 sm:px-6 py-3 w-full sm:w-80 bg-gray-800 bg-opacity-50 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-gray-500 transition-all duration-300"
              required
            >
            <button 
              type="submit" 
              class="w-full sm:w-auto px-6 sm:px-8 py-3 bg-white text-gray-900 rounded-full hover:bg-gray-200 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Suscribirse
            </button>
          </form>
        </div>
      </main>
  
      <!-- Footer -->
      <footer class="py-4 sm:py-6 px-4 sm:px-6">
        <div class="container mx-auto">
          <div class="text-gray-400 text-sm text-center">
            © 2023 Juárez IT PRO. Todos los derechos reservados.
          </div>
        </div>
      </footer>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  
  const email = ref('')
  const navItems = ['Inicio', 'Servicios', 'Sobre Nosotros', 'Contacto']
  
  const subscribeNewsletter = () => {
    // Aquí iría la lógica para manejar la suscripción al newsletter
    console.log('Email suscrito:', email.value)
    email.value = '' // Limpiar el campo después de enviar
  }
  </script>
  
  <style scoped>
  /* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap'); */
  
  body {
    font-family: 'Inter', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .animate-fade-in-up {
    animation: fadeInUp 0.8s ease-out forwards;
    opacity: 0;
  }
  
  .animation-delay-300 {
    animation-delay: 0.3s;
  }
  
  .animation-delay-600 {
    animation-delay: 0.6s;
  }
  
  .animation-delay-900 {
    animation-delay: 0.9s;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 640px) {
    .animate-fade-in-up {
      animation-name: fadeIn;
    }
  
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }
  </style>
